.pagination {
  @apply inline-flex border border-[#ECEDEE] divide-x divide-[#ECEDEE];
}

.pagination-item {
  @apply py-2 px-3 text-sm font-bold text-[#A6AEBC] inline-flex items-center justify-center cursor-pointer w-12;
}

.pagination-item.active {
  @apply bg-[#371AEE] text-white;
}
