/* Base */
@import "tailwindcss/base";
@import "./base/base.css";
@import "./base/typography.css";
@import "./base/animations.css";

/* Components */
@import "tailwindcss/components";
@import "./components/dashboard.css";
@import "./components/sidebar.css";
@import "./components/alert.css";
@import "./components/card.css";
@import "./components/tabs.css";
@import "./components/table.css";
@import "./components/pagination.css";
@import "./components/datepicker.css";
@import "./components/write-review-button.css";

/* Utilities */
@import "tailwindcss/utilities";
