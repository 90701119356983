.alert {
  @apply rounded py-3 px-5 bg-gray-200 inline-flex items-center justify-between text-sm font-medium shadow-sm w-full max-w-[658px] min-h-[46px] flex-shrink-0;
}

.alert.danger {
  @apply bg-red-200 text-[#650623];
}

.alert.success {
  @apply bg-green-100 text-green-800;
}
