/* Datepicker custom button */
.datepicker-button {
  @apply whitespace-nowrap inline-flex items-center rounded-md bg-[#F4F3FF] py-1.5 max-w-[370px] w-full relative cursor-pointer;
}

.datepicker-button-text {
  @apply text-sm font-medium text-primary-200 text-center;
}

.datepicker-button-chevron {
  @apply text-primary-200 text-xl absolute right-4 top-1/2 transform -translate-y-1/2;
}

/* Datepicker icon */
.datepicker-button-icon-wrapper {
  @apply w-14 h-8 border-r border-primary-200 inline-flex items-center justify-center flex-shrink-0;
}

.datepicker-button-icon {
  @apply text-primary-200 text-xl;
}

/* Popper */
.datepicker .react-datepicker {
  @apply border border-gray-200 rounded-md;
}

/* Popper triangle */
.datepicker[data-placement^="bottom"] .react-datepicker__triangle,
.datepicker[data-placement^="top"] .react-datepicker__triangle {
  @apply !-left-3;
}

.datepicker[data-placement^="bottom"] .react-datepicker__triangle::before {
  @apply !border-b-gray-200;
}

.datepicker[data-placement^="bottom"] .react-datepicker__triangle::after {
  @apply !border-b-[#F4F3FF];
}

.datepicker[data-placement^="top"] .react-datepicker__triangle::before {
  @apply !border-t-gray-200;
}

.datepicker[data-placement^="top"] .react-datepicker__triangle::after {
  @apply !border-b-gray-200;
}

/* Popper header */
.datepicker .react-datepicker__header {
  @apply bg-[#F4F3FF] border-b border-gray-200;
}

/* Popper month */
.datepicker .react-datepicker__current-month {
  @apply text-primary-200 font-sans font-semibold;
}

/* Popper arrows */
.datepicker .react-datepicker__navigation {
  @apply top-2;
}

.datepicker .react-datepicker__navigation-icon::before {
  @apply border-primary-200;
}

.datepicker .react-datepicker__navigation:hover *::before {
  @apply border-primary-200;
}

/* Popper days */
.datepicker .react-datepicker__day-name {
  @apply text-black font-sans font-medium;
}

.datepicker .react-datepicker__day {
  @apply text-black font-sans outline-none;
}

.datepicker .react-datepicker__day--selected {
  @apply bg-primary-200 text-white;
}

.datepicker .react-datepicker__day--selected:hover {
  @apply bg-primary-100;
}

.datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-transparent text-black;
}

/* Popper outside month */
.datepicker .react-datepicker__day--outside-month {
  @apply text-gray-500;
}
