.dashboard-wrapper {
  @apply bg-[url('../../assets/images/bg-gradient.svg')] bg-no-repeat bg-cover bg-bottom;
}

.dashboard-body {
  @apply flex h-[calc(100vh-64px)] overflow-hidden;
}

.dashboard-content {
  @apply flex w-full flex-col p-3 md:px-8 md:py-6 overflow-auto space-y-6;
}
