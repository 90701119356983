.sidebar-link {
  @apply flex items-center space-x-6 rounded-md py-2.5 px-5 text-sm transition-colors relative;
}

.sidebar-link:not(.active) {
  @apply bg-transparent text-[#222429];
}

.sidebar-link.active {
  @apply bg-primary-200  text-[#F8F7F7];
}

.sidebar-link:not(.active) {
  @apply hover:bg-[#F3F3FF];
}

/* .sidebar-link:hover .sidebar-link-icon {
  @apply text-[#F8F7F7];
} */

.sidebar-link-icon {
  @apply text-primary-100 text-xl transition-colors;
}

.sidebar-link.active .sidebar-link-icon {
  @apply text-[#F8F7F7];
}

.sidebar {
  @apply w-64 flex-shrink-0 overflow-auto h-full bg-white border-r border-gray-200 p-6 flex flex-col space-y-6;
}

/* For mobile only */
@media (max-width: 768px) {
  .sidebar-overlay {
    @apply fixed z-10 top-0 left-0 w-full h-full bg-black bg-opacity-60 opacity-0 invisible transition-all;
  }
  .sidebar {
    @apply absolute top-0 left-0 pt-20 h-full z-10 transition-transform transform-gpu -translate-x-full;
  }
  .sidebar-active .sidebar {
    @apply translate-x-0;
  }
  .sidebar-active .sidebar-overlay {
    @apply !opacity-100 !visible;
  }
}
