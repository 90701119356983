.table-wrapper {
  @apply bg-[#F8F7F7] border border-[#CDD0E3] border-opacity-75 rounded-md overflow-hidden  min-h-[492px];
}

.table {
  @apply w-full;
}

.table-header {
  @apply bg-[#F0F0F4] border-b border-[#CDD0E3] border-opacity-75;
}

.table-header-item {
  @apply p-3 text-xs font-normal text-[#A0AEC0] text-left uppercase;
}

.table-body {
  @apply divide-y divide-gray-300 bg-white;
}

.table-body-item {
  @apply py-3 px-4 text-sm;
}
